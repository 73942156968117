import React, { useState } from "react";

import {
  PhXBold,
  PhWarningOctagonFill,
  PhCheckCircleBold,
  PhEnvelopeSimpleBold,
  Spinner,
} from "../assets/icons/Icon";
import { useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { FormikInputFile, FormikInputTextOrMail } from "./form";
import {
  agentValidation,
  changeMailValidation,
  codeMailValidation,
  contactValidation,
  fileValidation,
  partnersValidation,
  priceValidation,
  statusValidation,
  testimonyValidation,
} from "../helpers/formValidations";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { useModal } from "../context/Modal";

const Modal = () => {
  const { isOpen, content, hideModal, offCanvas } = useModal();
  return (
    <>
      <div className={`modal-box ${isOpen ? "showIt" : ""}`}>
        <div
          className={`modal-box-content ${
            offCanvas ? "modal-off-canvas" : ""
          } `}
        >
          {!offCanvas && (
            <span
              className="modal-close"
              onClick={() => {
                hideModal();
                localStorage.removeItem("newMail");
              }}
            >
              <PhXBold />
            </span>
          )}
          <div className={`modal-container`}>{content}</div>
        </div>
      </div>
    </>
  );
};

export function OperationStatusModal({ props }) {
  const { type, message } = props;
  const { hideModal } = useModal();

  return (
    <div className="operationStatusModal">
      {type === "success" ? (
        <PhCheckCircleBold className="osm-success" />
      ) : (
        <PhWarningOctagonFill className="osm-error" />
      )}
      <p>{message}</p>
      <div className="osm-actions">
        <button
          className={type == "success" ? "osm-success" : "osm-error"}
          onClick={() => hideModal()}
        >
          J'ai compris
        </button>
        {/* <button>Un autre bouton</button> */}
      </div>
    </div>
  );
}

export function MailChangeModal() {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [changeMailSepTwo, setCMST] = useState(
    localStorage.getItem("newMail") ? true : false
  );
  const [changeMailData, setChangeMailData] = useState({
    email: localStorage.getItem("newMail")
      ? localStorage.getItem("newMail")
      : "",
    verificationCode: "",
  });
  function handleSubmitOne(values) {
    setLoading(true);
    axios
      .post("/user/reset/email/1", { newEmail: changeMailData.email })
      .then(() => {
        setCMST(true);
        setChangeMailData((prev) => {
          return { ...prev, email: changeMailData.email };
        });
        localStorage.setItem("newMail", changeMailData.email);
        toast.info("Un code a été envoyé à votre adresse e-mail.", {
          autoClose: 10000,
        });
        setLoading(false);
        showModal(<MailChangeModal />);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Une erreur a été rencontrée");
      })
      .finally();
  }
  function handleSubmitTwo(values) {
    setLoading(true);
    axios
      .post("/user/reset/email/2", {
        newEmail: values.email,
        emailResetCode: values.verificationCode,
      })
      .then((res) => {
        showModal(
          <OperationStatusModal
            props={{
              type: "success",
              message: `Votre adresse e-mail a été modifiée avec succès. Nous allons maintenant procéder au rechargement de la page.`,
            }}
          />
        );
        localStorage.removeItem("newMail");
        setLoading(false);
        setCMST(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        const { invalidResetCode } = err.response.data.message;
        if (invalidResetCode) {
          toast.error("Le code saisi est invalide.");
        } else {
          toast.error("Une erreur a été rencontrée");
        }
      })
      .finally();
  }

  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes-vous certain de vouloir modifier votre adresse e-mail ?
              `}
        handle={handleSubmitOne}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setChangeMailData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="change-modal">
      {changeMailSepTwo === false ? (
        <Formik
          initialValues={changeMailData}
          onSubmit={handleConfirm}
          validationSchema={changeMailValidation}
          enableReinitialize
        >
          {(values) => (
            <Form>
              {FormikInputTextOrMail(
                {
                  type: "email",
                  label: "Nouvelle adresse e-mail",
                  placeholder: "Veuillez saisir votre nouvelle adresse e-mail",
                  name: "email",
                  icon: <PhEnvelopeSimpleBold />,
                },
                false,
                handleInput
              )}
              <div className="info">
                Vous recevrez un e-mail de confirmation à l'adresse que vous
                avez indiquée dans votre demande. Cet e-mail inclura un code de
                vérification unique. Merci de vérifier votre boîte de réception,
                y compris votre dossier de courriers indésirables (SPAM), afin
                de trouver cet e-mail. Il se peut qu'il y ait un léger délai
                avant sa réception. Lorsque vous aurez reçu le code, veuillez
                retourner sur la page de modification d'adresse e-mail et suivre
                les instructions pour entrer le code dans l'espace dédié.
              </div>
              <div className="action">
                <div>
                  <button
                    onClick={() => {
                      setCMST(false);
                      hideModal();
                    }}
                  >
                    Annuler
                  </button>
                  <button type="submit" disabled={loading}>
                    {loading ? <Spinner /> : "Suivant"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={changeMailData}
          onSubmit={handleSubmitTwo}
          validationSchema={codeMailValidation}
        >
          {(values) => (
            <Form>
              {FormikInputTextOrMail({
                type: "text",
                label: "Code",
                placeholder: " code reçu",
                name: "verificationCode",
              })}
              <div className="info">
                Saisissez le code que vous avez reçu afin de confirmer le
                changement d'adresse e-mail.
              </div>
              <div className="action">
                <div>
                  <button
                    onClick={() => {
                      setCMST(false);
                      hideModal();
                      localStorage.removeItem("newMail");
                    }}
                  >
                    Annuler
                  </button>
                  <button type="submit" disabled={loading}>
                    {loading ? <Spinner /> : "Changer le mail"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export function ModalChangePhoneOrNumber({ left, leftContact }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);

  const [changeContact, setChangeContact] = useState({
    contact: "",
  });

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put(
        "/user/update/phone-whatsapp",
        left === "contact"
          ? {
              newPhone: leftContact,
              newWhatsapp: parseInt(changeContact.contact),
            }
          : {
              newPhone: parseInt(changeContact.contact),
              newWhatsapp: leftContact,
            }
      )
      .then((res) => {
        showModal(
          <OperationStatusModal
            props={{
              type: "success",
              message: `Votre ${
                left === "contact" ? "numéro whatsApp" : "contact"
              } a été modifié avec succès. La page va maintenant être rechargée.`,
            }}
          />
        );
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        const { phoneOrWhatsappExist } = err.response.data.message;
        if (phoneOrWhatsappExist) {
          toast.error("Ce numéro est déjà associé à un compte.");
        } else {
          toast.error("Une erreur a éte rencontrée");
        }
      })
      .finally();
  };

  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Etes vous sur de vouloir changer le       ${
          left === "contact" ? "numéro whatsApp" : "numéro de téléphone"
        } ?`}
        handle={handleSubmit}
      />
    );
  };
  return (
    <div className="change-modal">
      <Formik
        initialValues={changeContact}
        onSubmit={handleConfirm}
        validationSchema={contactValidation}
        enableReinitialize
      >
        {(values) => (
          <Form>
            <div>
              <label htmlFor="">
                {left === "contact"
                  ? "Nouveau Numero Whatsapp"
                  : "Nouveau Numero"}{" "}
                <span>*</span>
              </label>
              <PhoneInput
                country={"bj"}
                value={changeContact.contact}
                onChange={(e) => setChangeContact({ contact: e })}
                placeholder="+229 00000000"
              />
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                <ErrorMessage name={"contact"} />
              </span>
            </div>
            <div className="info">
              Veuillez renseigner le nouveau{" "}
              {left === "contact" ? "numéro whatsApp" : "contact"} à associer a
              ce compte
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                <button type="submit" disabled={loading}>
                  Changer
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function ModalChangeFile({ tabDemande, index }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [newFile, setNewFile] = useState({
    newFile: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("currentFileUrl", tabDemande.docs[index].docPath);
    formData.append("typeDoc", tabDemande.docs[index].name);
    formData.append("newFile", newFile.newFile);

    try {
      await axios.put(
        `/client-requests/${tabDemande._id}/update/file`,
        formData
      );
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Le fichier a été remplacé avec succès. Nous allons à présent recharger la page`,
          }}
        />
      );
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };

  const file = [
    {
      label: `Nouvelle`,
      name: "newFile",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes-vous sûr de vouloir changer le fichier ?`}
        handle={handleSubmit}
      />
    );
  };
  return (
    <div className="change-modal">
      <Formik
        initialValues={newFile}
        onSubmit={handleConfirm}
        validationSchema={fileValidation}
      >
        {(formik) => (
          <Form>
            {file.map((item, ind) =>
              FormikInputFile(ind, item, formik, setNewFile, newFile)
            )}
            <div className="info">
              Le document en question a été conçu pour être modifié uniquement
              selon les instructions et les demandes spécifiques qui vous ont
              été transmises. Toute modification apportée en dehors de ce
              contexte peut entraîner des conséquences indésirables et des
              résultats non conformes à vos attentes.Veuillez noter que si vous
              décidez de modifier le document sans nous en informer au
              préalable, nous nous dégageons de toute responsabilité concernant
              les résultats obtenus. En respectant cette procédure, nous
              pourrons garantir la cohérence, la qualité et la conformité des
              modifications apportées au document.
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                <button type="submit" disabled={loading}>
                  {loading ? <Spinner /> : "Changer le fichier"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function ConfirmModal({ text, handle }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const HandleConfirm = (status) => {
    if (!status) {
      return hideModal(), toast.info("Action annulée");
    } else {
      setLoading(true);
      handle();
      hideModal();
    }
  };
  return (
    <div className="change-modal">
      <p className="confirm-text">{text}</p>
      <div className="action">
        <div>
          <button
            onClick={() => {
              HandleConfirm(false);
            }}
          >
            Annuler
          </button>
          <button onClick={() => HandleConfirm(true)} disabled={loading}>
            {loading ? <Spinner /> : "Confirmer"}
          </button>
        </div>
      </div>
    </div>
  );
}

export function ModalGiveFolderToAgent({ dossier }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(true);
  const [agentList, setAgentList] = useState([]);
  const [giveToAgent, setGiveTogent] = useState({
    requestId: dossier._id,
    agentId: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.put(`admin/client-request/add-agent`, {
        requestId: giveToAgent.requestId,
        agentId: giveToAgent.agentId,
      });
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Le dossier a été correctement attribué à l'agent.`,
          }}
        />
      );

      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };

  const field = [
    {
      label: `Veuillez choisir l'agent.`,
      name: "agentId",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes-vous sûr de vouloir attribuer `}
        handle={handleSubmit}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setGiveTogent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const response = await axios.get("/admin/user/agent/all");
        setAgentList(response.data.data);
        setLoadingAgent(false);
      } catch (error) {
        toast.error("Une erreur a été rencontrer");
        setLoading(false);
      }
    };
    fetchAgent();
  }, []);

  return (
    <div className="change-modal">
      {loadingAgent ? (
        <p style={{ textAlign: "center" }}>
          <Spinner />
        </p>
      ) : (
        <Formik
          initialValues={giveToAgent}
          onSubmit={handleConfirm}
          validationSchema={agentValidation}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              {field.map((item, ind) =>
                FormikInputTextOrMail(item, false, handleInput, agentList)
              )}
              <div className="info">
                Veuillez choisir l'agent à qui attribué le dossier
              </div>
              <div className="action">
                <div>
                  <button
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    Annuler
                  </button>
                  <button type="submit" disabled={loading}>
                    {loading ? <Spinner /> : "Attribué"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export function ModalSetFolderPrice({ dossier }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [reqPrice, setReqPrice] = useState({
    price: "",
    requestId: dossier._id,
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.put(`admin/client-request/price`, {
        requestId: reqPrice.requestId,
        price: parseInt(reqPrice.price),
      });
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Le prix a été correctement ajouté au dossier.`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée.");
      setLoading(false);
    }
  };

  const field = [
    {
      label: `Veuillez renseigner le prix`,
      name: "price",
      type: "number",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes-vous sûr de vouloir ajouter ce prix ?`}
        handle={handleSubmit}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setReqPrice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="change-modal">
      <Formik
        initialValues={reqPrice}
        onSubmit={handleConfirm}
        validationSchema={priceValidation}
        enableReinitialize
      >
        {() => (
          <Form>
            {field.map((item, ind) =>
              FormikInputTextOrMail(item, false, handleInput)
            )}
            <div className="info">
              Veuillez renseigner le prix de ce dossier(Minimum 200 Fcfa)
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                {parseInt(reqPrice.price) >= 200 && (
                  <button type="submit" disabled={loading}>
                    {loading ? <Spinner /> : "Attribué"}
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export function ModalSetFolderStatut({ dossier }) {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    status: "",
    statusReasons: "",
    requestId: dossier._id,
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.put(`admin/client-request/update/status`, {
        requestId: reqStatus.requestId,
        status: reqStatus.status,
        statusReasons: reqStatus.statusReasons,
      });
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Statut et raisons modifiés avec succès.`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontée");
      setLoading(false);
    }
  };

  const field = [
    {
      label: `Choisir le statut`,
      name: "status",
    },
    {
      label: `Raison du statut`,
      name: "statusReasons",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes vous sûr de vouloir confirmer ?`}
        handle={handleSubmit}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setReqStatus((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="change-modal">
      <Formik
        initialValues={reqStatus}
        onSubmit={handleConfirm}
        validationSchema={statusValidation}
        enableReinitialize
      >
        {() => (
          <Form>
            {field.map((item, ind) =>
              FormikInputTextOrMail(item, false, handleInput)
            )}
            <div className="info">
              Veuillez remplir les champs pour soumettre le statut
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                <button type="submit" disabled={loading}>
                  {loading ? <Spinner /> : "Attribué"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function ModalAddPartners() {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [partnersForm, setPartnersForm] = useState({
    partner_name: "",
    logo: "",
    partner_link: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("partner_name", partnersForm.partner_name);
    formData.append("logo", partnersForm.logo);
    formData.append("partner_link", partnersForm.partner_link);

    try {
      await axios.post(`partner/`, formData);
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Partenaire ajouté avec succès`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  const field = [
    {
      label: `Le nom du partenaire`,
      name: "partner_name",
    },
    {
      label: `Logo du partenaire`,
      name: "logo",
    },
    {
      label: "Le lien du site du partenaire",
      name: "partner_link",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Etes vous sûr de vouloir confirmer ?`}
        handle={handleSubmit}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setPartnersForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="change-modal">
      <Formik
        initialValues={partnersForm}
        onSubmit={handleConfirm}
        validationSchema={partnersValidation}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            {field.map((item, ind) =>
              item.name === "logo"
                ? FormikInputFile(
                    ind,
                    item,
                    formik,
                    setPartnersForm,
                    partnersForm
                  )
                : FormikInputTextOrMail(item, false, handleInput)
            )}
            <div className="info">
              Veuillez remplir les champs pour ajouter le partenaire
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                <button type="submit" disabled={loading}>
                  {loading ? <Spinner /> : "Ajouter"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function ModalAddTestimony() {
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [testimonyForm, setTestimonyForm] = useState({
    username: "",
    content: "",
    picture: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("username", testimonyForm.username);
    formData.append("content", testimonyForm.content);
    formData.append("picture", testimonyForm.picture);

    try {
      await axios.post(`notice/`, formData);
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Témoignage ajouté avec succès.`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  const field = [
    {
      label: `Nom & Prénoms du témoin`,
      name: "username",
    },
    {
      label: `Contenu du témoignagne`,
      name: "content",
    },
    {
      label: `Photo du témoin`,
      name: "picture",
    },
  ];
  const handleConfirm = () => {
    showModal(
      <ConfirmModal
        text={`Êtes vous sûr de vouloir confirmer ?`}
        handle={handleSubmit}
      />
    );
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setTestimonyForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="change-modal">
      <Formik
        initialValues={testimonyForm}
        onSubmit={handleConfirm}
        validationSchema={testimonyValidation}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            {field.map((item, ind) =>
              item.name === "picture"
                ? FormikInputFile(
                    ind,
                    item,
                    formik,
                    setTestimonyForm,
                    testimonyForm
                  )
                : FormikInputTextOrMail(item, false, handleInput)
            )}
            <div className="info">
              Veuillez remplir les champs pour ajouter le témoignage
            </div>
            <div className="action">
              <div>
                <button
                  onClick={() => {
                    hideModal();
                  }}
                >
                  Annuler
                </button>
                <button type="submit" disabled={loading}>
                  {loading ? <Spinner /> : "Ajouter"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Modal;
