import React, { useEffect, useState } from "react";
import { DashGreenButton } from "../../ui-kits/button/button";
import { ConfirmModal, ModalAddPartners } from "../../ui-kits/Modal";
import { Img } from "../../ui-kits/kits";
import axios from "axios";
import { toast } from "react-toastify";
import { Popover } from "@material-ui/core";
import {
  PhDotsThreeOutlineFill,
  PhDotsThreeOutlineVerticalBold,
  PopoverIcon,
} from "../../assets/icons/Icon";
import withAuth from "../../hoc/WithAuth";
import { useModal } from "../../context/Modal";

const PartnersConfigurate = () => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedPartenaire, setSP] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [listParteners, setListParteners] = useState([]);
  const [loading, setLoading] = useState(true);

  const { hideModal, showModal } = useModal();

  const getAllPartners = async () => {
    try {
      const response = await axios.get(`partner/`);
      setListParteners(response.data.data.data);
      setLoading(false);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };
  const deletePartners = async (item) => {
    hideModal();
    toast.info("Suppression du logo lancée");
    try {
      await axios.delete(`partner/` + item._id);
      toast.success("Operation réussir");
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
    }
  };

  const handleOpenPopover = (item, e) => {
    setSP(item);
    setAnchorElement(e?.currentTarget);
    setVisiblePopover(true);
  };
  const ClosePopover = () => {
    setAnchorElement(null);
    setVisiblePopover(false);
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <Popover
            id="popover"
            open={visiblePopover}
            anchorEl={anchorElement}
            onClose={ClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ul>
              <li
                onClick={() => {
                  ClosePopover();
                  showModal(
                    <ConfirmModal
                      text={`Etes vous sûr de vouloir supprimer ce logo ?`}
                      handle={() => deletePartners(selectedPartenaire)}
                    />
                  );
                }}
              >
                <PopoverIcon /> Effacer
              </li>
            </ul>
          </Popover>
          <h2>Les partenaires de REFI</h2>
          <div className="parteners-containers">
            {listParteners.length > 0
              ? listParteners.map((item) => (
                  <div key={item.partner_name} className="logo-container">
                    <img src={item.logo_url} alt="" />
                    <div>
                      <a
                        href={item.partner_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visiter le lien
                      </a>
                      <p>{item.partner_name}</p>
                    </div>
                    <div onClick={(e) => handleOpenPopover(item, e)}>
                      <PhDotsThreeOutlineFill />
                    </div>
                  </div>
                ))
              : "Pas de partenaires disponible"}
          </div>
          <div>
            <DashGreenButton
              content="Ajouter un partenaire"
              link=""
              onClick={() => showModal(<ModalAddPartners />)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withAuth(PartnersConfigurate, ["superAdmin"]);
