import React from "react";
import { langSelector } from "../../helpers/langs";
import Article2 from "../../assets/img/article2.jpeg";
import Article1 from "../../assets/img/article1.jpeg";
import Article3 from "../../assets/img/article3.jpeg";

const HomeInformation = () => {
  const l = langSelector("homePage").homeInformations;

  const articleList = [
    {
      img: Article1,

      title:
        "SÉCURISATION FONCIÈRE AU BÉNIN : Abdou-Wahab Say Garba propose une révision du code foncier",
      route:
        "https://daabaaru.bj/securisation-fonciere-au-benin-abdou-wahab-say-garba-propose-une-revision-du-code-foncier/",
      label: l.buttonText,
    },
    {
      img: Article2,
      title:
        "Les explications du DG ANDF sur l’expiration de la période transitoire",

      route:
        "https://www.24haubenin.info/?Les-explications-du-DG-ANDF-sur-l-expiration-de-la-periode-transitoire",
      label: l.buttonText,
    },
    {
      img: Article3,

      title:
        "IMPACTS DE LA MAFIA FONCIERE ET DES CONFLITS DOMANIAUX SUR LE DEVELOPPEMENT DURABLE AU BENIN",

      route:
        "https://www.linkedin.com/pulse/impacts-de-la-mafia-fonciere-et-des-conflits-sur-le-au-agossadou",
      label: l.buttonText,
    },
  ];
  return (
    <div className="information">
      <div className="top">
        <h3>Informations</h3>
        <p>
          Voici quelques-uns de nos articles récents sur l'immobilier, proposant
          un contenu riche pour enrichir votre culture dans ce domaine.
        </p>
      </div>
      <div className="article-container">
        {articleList.map((item, index) => (
          <div
            key={"Article " + index + 1}
            style={{ backgroundImage: "url(" + item.img + ")" }}
          >
            <div>
              <h2>{item.title}</h2>
              <p>{item.desc}</p>
              <a href={item.route} target="_blank" rel="noopener noreferrer">
                {item.label}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeInformation;
